<template>
<div class='logo-wrap'>
  <img src='../assets/logo.png' alt=''>
</div>
</template>

<script>
export default {
  name: 'logo'
}
</script>

<style lang='scss' scoped>
.logo-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 80px;
    height: 80px;
  }
}
</style>
